import { projectFirestore } from "../../firebase/config";
import store from "../../store";
import {role,user} from '../Utils'



const send_msg_to_sepecific_user = async(target,msg_object) => {
    try{
        if(!target) throw new Error('חסר אובייקט היעד בפונקציה - send_msg_to_sepecific_user!')
        if(!msg_object) throw new Error('חסר אובייקט ההודעה בפונקציה - send_msg_to_sepecific_user!')
        const doc = projectFirestore.collection('Users').doc(target.target_uid).collection('Msgs').doc()
        await doc.set({
            ...msg_object,
            ...target,
            uid:doc.id
        })
    }catch(err){
        throw new Error(err)
    }
}

const send_msg_to_specific_users = async(users,users_uid_array,msg_object) => {
    try{
        if(!users) throw new Error('חסר מערך של המשתמשים - send_msg_to_specific_users!')
        if(!users_uid_array) throw new Error('חסר מערך של מזהי משתמשים בפונקציה - send_msg_to_specific_users!')
        if(!msg_object) throw new Error('חסר אובייקט ההודעה בפונקציה - send_msg_to_specific_users!')
        const promises = []
        for(const uid of users_uid_array){
            const index = users.findIndex(user => user.value == uid)
            if(index != -1){
                const doc = projectFirestore.collection('Users').doc(uid).collection('Msgs').doc()
                promises.push(doc.set({
                    ...msg_object,
                    target_uid:uid,
                    target_name:users[index].label.split('-')[1].trim(),
                    uid:doc.id
                }))
            }
        }
    
        await Promise.all(promises)
    }catch(err){
        throw new Error(err)
    }
}

const delete_specific_msg = async(user_uid,msg_uid) => {
    if(!user_uid) throw new Error('חסר מזהה משתמש בפונקציה - delete_specific_msg!')
    if(!msg_uid) throw new Error('חסר מזהה ההודעה בפונקציה - delete_specific_msg')
    try{
        await projectFirestore.collection('Users').doc(user_uid)
        .collection('Msgs').doc(msg_uid).delete()
    }catch(err){
        throw new Error(err)
    }

}

const delete_specific_users_all_msgs = async(user_uid) => {
    try{
        if(!user_uid) throw new Error('חסר מזהה משתמש בפונקציה - delete_specific_users_all_msgs!')
        const collectionRef = projectFirestore.collection('Users').doc(user_uid).collection('Msgs')
        collectionRef.get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.delete();
            });
        })
    }catch(err){
        throw new Error(err)
    }
}

// UPDATE:
// ALL MSGs deleted -> go fo ARCHIVE (fb.collection('Archive)) -> each doc is user UID -> collection ('msgs') 
// In futore: there will be more Collections in each user's Archive.

const get_msgs_from_specific_user = async(user_uid) => {
    try{
        if(!user_uid) throw new Error('חסר מזהה משתמש בפונקציה - get_msgs_from_specific_user!')
        const msgs = await projectFirestore.collection('Users').doc(user_uid).collection('Msgs').get()
        return msgs
    }catch(err){
        throw new Error(err)
    }
}

const get_all_unreader_agent_msgs = () => {
    try {
        const unsubscribe = projectFirestore.collection('Users').doc(user.value.uid).collection('Msgs')
            .where('read', '==', false)
            .onSnapshot(snapshot => {
                const unread_msgs = snapshot.docs.map(doc => doc.data());
                store.state.unreaded_msgs = unread_msgs; // Update Vuex store with the new unread messages
            }, err => {
                throw new Error(err);
            });

        return unsubscribe; // Return the unsubscribe function to call when you want to stop listening to changes
    } catch (err) {
        console.error("Failed to fetch unread messages: ", err);
        throw new Error(err);
    }
};

const get_all_msgs_from_all_users = async() => {
    try{
        const msgs = await projectFunctions.httpsCallable("get_all_users_msg")();
        return msgs.data
    }catch(err){
        throw new Error(err)
    }
}

// send msg to all agents (sales manager -> sends an update to ALL agents.)
const send_msg_to_all_agents = async(users,msg_object) => {
    try{
        if(!users) throw new Error('חסר מערך משתמשים בפונקציה - send_msg_to_all_agents!')
        if(!msg_object) throw new Error('חסר אובייקט ההודעה בפונקציה - send_msg_to_all_agents!')
        const promises = []
        for(const user of users){
            const target = {
                target_uid:user.value,
                target_name:user.label.split('-')[1].trim(),
            }
            promises.push(
                send_msg_to_sepecific_user(target,msg_object)
            )
        }
        
        await Promise.all(promises)
    }catch(err){
        throw new Error(err)
    }
}

// readMsg -> when user reads a msg -> change state to "read" (I want to know who reads what).
const read_msg = async(user_uid,msg_uid) => {
    try{
        if(!msg_uid) throw new Error('חסר מזהה הודעה בפונקציה - read_msg !')
        if(!user_uid) throw new Error('חסר מזהה משתמש בפונקציה - read_msg!')
        await projectFirestore.collection('Users').doc(user_uid).collection('Msgs').doc(msg_uid)
        .set({
            read:true
        },{merge:true})
    }catch(err){
        throw new Error(err)
    }
}
// isRead -> send msg UID return read (T o F) (= True or False)
const is_msg_read = async(user_uid,msg_uid) => {
    try{
        if(!user_uid) throw new Error('חסר מזהה משתמש בפונקציה - is_msg_read!')
        if(!msg_uid) throw new Error('חסר מזהה הודעה בפונקציה - is_msg_read !')
        const doc = await projectFirestore.collection('Users').doc(user_uid).collection('Msgs').doc(msg_uid).get()
        return doc.data().read
    }catch(err){
        throw new Error(err)
    }
}

// forward msg to another UID (will appear as a btn in UI, user clicks a mgs (-> msg objcet) -> send as new msg to recipiant UID (at UI -> user will choose target user))
// get msg object + user_uid => 'send_msg_to_sepecific_user'
// keep in mind -> when forwarding DONT copy read:true! -> read= false;
const forward_msg_to_another_user = async(user_uid,msg_object) => {
    try{
        if(!user_uid) throw new Error('חסר מזהה משתמש בפונקציה - forward_msg_to_another_user!')
        if(!msg_object) throw new Error('חסר אובייקט ההודעה בפונקציה - forward_msg_to_another_user!')
        await send_msg_to_sepecific_user(user_uid,msg_object)
    }catch(err){
        throw new Error(err)
    }
}


//ADD PROTECTIONS! CALLBACKS!

// send a TRACKER msg -> same as regular msg, BUT, add propery: track:true + 
// Admin's only. (role >= 10);
const send_tracker_msg = async(user_uid,msg_object) => {
    try{
        if(!user_uid) throw new Error('חסר מזהה משתמש בפונקציה - send_tracker_msg!')
        if(!msg_object) throw new Error('חסר אובייקט ההודעה בפונקציה - send_tracker_msg!')
        if(role.value >= 10){
            const doc = projectFirestore.collection('Users').doc(user_uid).collection('Msgs').doc()
            await doc.set({
                msg_object
            })
        }else{
            throw new Error('אין לך הרשאה!')
        }
    }catch(err){
        throw new Error(err)
    }
}

// get all TRACKER msg's (where track:true)
const get_all_trackers_msges = async() => {
    try{
        if(role.value >= 10){
            const msgs = await projectFunctions.httpsCallable("get_all_trackers_msgs")();
            return msgs.data
        }else{
            throw new Error('אין לך הרשאה!')
        }
    }catch(err){
        throw new Error(err)
    }
}

// ...continue tracker logic...


export{
    send_msg_to_sepecific_user,
    send_msg_to_specific_users,
    delete_specific_msg,
    get_all_msgs_from_all_users,
    get_msgs_from_specific_user,
    delete_specific_users_all_msgs,
    send_msg_to_all_agents,
    read_msg,
    is_msg_read,
    forward_msg_to_another_user,
    send_tracker_msg,
    get_all_trackers_msges,
    get_all_unreader_agent_msgs
}