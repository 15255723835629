
// collection => Chaiser - write and read user - rule (user uid == auth.id || admin)

import { projectFirestore } from "../../firebase/config"
import store from "../../store"
import { slide_pop_successs } from '../../Scripts/Msgs';



// before user leave - save the cart in the collection avbove with date

const save_user_cart = async() => {
    if(store.state.user && !store.state.user.agent){
        if(store.state.cart.items.length > 0 ){
            await projectFirestore.collection('CartChaser').doc(store.state.user.uid)
            .set({
                items:store.state.cart.items,
                date: new Date()
            })
        }else{
            if(store.state.cart.is_cart_reloaded){
                await projectFirestore.collection('CartChaser').doc(store.state.user.uid).delete()
            }
        }
    }else{
        //if agent exist
        console.log('agent exist');
    }
}

// func: chek_open_cart(user_uid) - get the cart if exist 

const check_open_cart = async(user) => {
    if(!user.agent){
        const doc = await projectFirestore.collection('CartChaser').doc(user.uid).get()
        if(doc.exists) {
            store.state.cart.is_cart_reloaded = true
            store.state.cart.items = doc.data().items
            slide_pop_successs("העגלה נטענה בהצלחה!");
        }
    }else{
        // if agent exist 
    }
}

export{
    save_user_cart,
    check_open_cart
}

