import { projectFirestore } from "../../firebase/config";

// return item by Barcode
const getP_barcode = async(barcode) => {
    try{
        if(!barcode){
            throw new Error('לא הוזן ברקוד המוצר ע"מ לקבל תוצאות!')
        }
        if(typeof(barcode) === 'number'){
            throw new Error('ברקוד המוצר צריך להיות סטרינג!')
        }
        const docs  = await projectFirestore.collection('Items').where('barcode','==',barcode).get()
        if(!docs.empty){
            return docs.docs.map(doc => doc.data())
        }
        throw new Error(`אין פריטים השייכים לברקוד:${barcode}!`)
        
    }catch(err){
        throw new Error(err)
    }
    
}

// return item by Makat
const getP_makat = async(makat) => {
    try{
        if(!makat){
            throw new Error('לא הוזן מזהה המוצר (מקט) ע"מ לקבל תוצאות!')
        }
        if(typeof(makat) === 'number'){
            throw new Error('מזהה המוצר (מקט) צריך להיות סטרינג!')
        }

        const doc  = await projectFirestore.collection('Items').doc(makat).get()

        if(!doc.exists){
            throw new Error(`המוצר ${makat} זה אינו קיים במערכת!`) 
        }
    
        return doc.data()
    }catch(err){
        throw new Error(err)
    }
   
}

// return array of products by Brand
const getP_brand = async(brand) => {
    try{
        if(!brand){
            throw new Error('לא הוזן שם המותג ע"מ לקבל תוצאות!')
        }
        if(typeof(brand) === 'number'){
            throw new Error(`שם המותג ${brand} צריך להיות סטרינג!`)
        }
        const docs  = await projectFirestore.collection('Items').where('brand','==',brand).get() 
        if(!docs.empty){
            return docs.docs.map(doc => doc.data())
        }
        throw new Error(`אין פריטים השייכים למותג:${brand}!`)
    }catch(err){
        throw new Error(err)
    }
    

}

// return products by Country
const getP_country = async(country) => {
    try{
        if(!country){
            throw new Error('לא הוזן שם המדינה ע"מ לקבל תוצאות!')
        }
        if(typeof(country) === 'number'){
            throw new Error(`שם המדינה ${country} צריך להיות סטרינג!`)
        }
        const docs  = await projectFirestore.collection('Items').where('origin','==',country).get() 
        if(!docs.empty){
            return docs.docs.map(doc => doc.data())
        }
        throw new Error(`אין פריטים השייכים לשם המדינה: ${country}!`)
    }catch(err){
        throw new Error(err)
    }
    
}

// return products by Merakez
const getP_merakez = async(merakez) => {
    try{
        if(!merakez){
            throw new Error('לא הוזן פריט מרכז ע"מ לקבל תוצאות!')
        }
        if(typeof(merakez) === 'number'){
            throw new Error(`פריט המרכז ${merakez} צריך להיות סטרינג!`)
        }
        const docs  = await projectFirestore.collection('Items').where('paritMerakez','==',merakez).get() 
        if(!docs.empty){
            return docs.docs.map(doc => doc.data())
        }

        throw new Error(`לא קיימים מוצרים השייכים למרכז: ${merakez}`)

    }catch(err){
        throw new Error(err)
    }
   
}

// return products by last update date
const getP_lastUpdate = async(date) => {
    try{
        if(!date){
            throw new Error('לא הוזן תאריך ע"מ לקבל תוצאות!')
        }
        if(typeof(merakez) === 'number'){
            throw new Error(` תאריך ${date} צריך להיות סטרינג!`)
        }
        const docs  = await projectFirestore.collection('Items').where('last_update','==',date).get() 
        if(!docs.empty){
            return docs.docs.map(doc => doc.data())
        }

        throw new Error('לא קיימים מוצרים השייכים לתאריך שהוזן')


    }catch(err){
        throw new Error(err)
    }
    
}
// return products if Pikadon > 0
const getP_pikadon = async() => {
    try{
        const docs  = await projectFirestore.collection('Items').where('pikadon','>',0).get() 
        if(!docs.empty){
            return docs.docs.map(doc => doc.data())
        }
        throw new Error('לא קיימים מוצרים עם פיקדון מעל 0!')
    }catch(err){
        throw new Error(err)
    }
}

// return only Kosher Products
const getP_kosher = async() => {
    try{
        const docs  = await projectFirestore.collection('Items').where('kosher_type','not-in',['null','לא כשר','ללא כשרות']).get() 
        if(!docs.empty){
            return docs.docs.map(doc => doc.data())
        }
        throw new Error('לא קיימים מוצרים כשרים!')
    }catch(err){
        throw new Error(err)
    }
    
}

// return only showOnWeb = 'H'
const getP_showOnWeb = async(type) => {
    try{
        const docs  = await projectFirestore.collection('Items').where('showOnWeb','==',type).get() 
        if(!docs.empty){
            return docs.docs.map(doc => doc.data())
        }
        throw new Error('לא קיימים מוצרים שהם show on web!')
    }catch(err){
        throw new Error(err)
    }
}

// SET FUNCTIONS

// validate if item exist
const check_if_item_exist = async(product_id) => {
    try{
        const doc  = await projectFirestore.collection('Items').doc(product_id).get()
        if(doc.exists) return true
        return false
    }catch(err){
        throw new Error(err)
    }
}

const check_if_category_exist = async(category) => {
    try{
        const docs  = await projectFirestore.collection('Categories').where('uid','==',category).get()
        if(!docs.empty) return docs.docs[0]?.data()
        return false
    }catch(err){
        throw new Error(err)
    }
}

const check_if_sub_category_belong_to_category = async(product_id,sub_category) => {
    try{
        const item = await getP_makat(product_id)
        // check if category exist and return the category
        const category = await check_if_category_exist(item.category)
        if(category){
            const docs = await projectFirestore.collection('Categories').doc(category.uid).collection('Subs')
            .where('uid','==',sub_category).get()
            if(!docs.empty) {
                return true
            }
            return false
        }
        throw new Error(`ע"מ להזין תת מחלקה למוצר ${product_id} צריך להיות קיימת מחלקה!`)
    }catch(err){
        throw new Error(err)
    }
}

const setP_image = async(product_id,image_url) => {
    try{
        if(!image_url){
            throw new Error(`image_url is missing`)
        }
        if(!product_id){
            throw new Error(`Product id is missing`)
        }
        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }
        if(typeof(image_url) === 'number'){
            throw new Error('The image url must to be a string!')
        }
        if(await check_if_item_exist(product_id)){
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                image:image_url
            },{merge:true})
        }
        else{
            throw new Error(`This product id: ${product_id} does not exist`)
        }
    }catch(err){
        throw new Error(err)
    }
}

const setP_measure = async(product_id,measure_unit) => {
    try{
        if(!measure_unit){
            throw new Error(`measure_unit is missing`)
        }
        if(!product_id){
            throw new Error(`Product id is missing`)
        }
        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }
        if(typeof(measure_unit) === 'number'){
            throw new Error('The measure_unit must to be a string!')
        }
        if(await check_if_item_exist(product_id)){
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                measure:image_url
            },{merge:true})
        }
        else{
            throw new Error(`This product id: ${product_id} does not exist`)
        }
    }catch(err){
        throw new Error(err)
    }
}

const setP_brand = async(product_id,brand) => {
    try{
        if(!brand){
            throw new Error(`brand is missing`)
        }
        if(!product_id){
            throw new Error(`Product id is missing`)
        }
        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }
        if(typeof(brand) === 'number'){
            throw new Error('The measure_unit must to be a string!')
        }
        if(await check_if_item_exist(product_id)){
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                brand
            },{merge:true})
        }
        else{
            throw new Error(`This product id: ${product_id} does not exist`)
        }
    }catch(err){
        throw new Error(err)
    }
}

const setP_kosher_type = async(product_id,kosher_type=null) => {
    try{
        if(!product_id){
            throw new Error(`Product id is missing`)
        }
        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }
        if(typeof(kosher_type) === 'number'){
            throw new Error('The measure_unit must to be a string!')
        }
        if(await check_if_item_exist(product_id)){
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                kosher_type
            },{merge:true})
        }
        else{
            throw new Error(`This product id: ${product_id} does not exist`)
        }
    }catch(err){
        throw new Error(err)
    }
}

const setP_order = async(product_id,order) => {
    try{
        if(!order){
            throw new Error(`Product order is missing`)
        }
        if(!product_id){
            throw new Error(`Product id is missing`)
        }
        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }
        if(typeof(order) === 'string'){
            throw new Error('The product order must to be a number!')
        }
        if(await check_if_item_exist(product_id)){
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                order
            },{merge:true})
        }
        else{
            throw new Error(`This product id: ${product_id} does not exist`)
        }
    }catch(err){
        throw new Error(err)
    }
}

const setP_size = async(product_id,size) => {
    try{
        if(!size){
            throw new Error(`Product size is missing`)
        }
        if(!product_id){
            throw new Error(`Product id is missing`)
        }
        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }
        if(typeof(size) === 'string'){
            throw new Error('The product size must to be a number!')
        }
        if(await check_if_item_exist(product_id)){
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                size
            },{merge:true})
        }
        else{
            throw new Error(`This product id: ${product_id} does not exist`)
        }
    }catch(err){
        throw new Error(err)
    }
}

const setP_category = async(product_id,category) => {
    try{
        if(!category){
            throw new Error(`category is missing`)
        }
        if(!product_id){
            throw new Error(`Product id is missing`)
        }
        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }
        if(typeof(category) === 'number'){
            throw new Error('The product category must to be a string!')
        }
        if(!await check_if_category_exist(category)){
            throw new Error(`This category: ${category} does not exist!`)
        }
        if(await check_if_item_exist(product_id)){
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                category,
                sub_category: null
            },{merge:true})
        }
        else{
            throw new Error(`הפריט הזה ${product_id} לא קיים!`)
        }
    }catch(err){
        throw new Error(err)
    }
}

const setP_sub_category = async(product_id,sub_category) => {
    try{
        if(!sub_category){
            throw new Error(`sub_category is missing`)
        }
        if(!product_id){
            throw new Error(`Product id is missing`)
        }
        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }
        if(typeof(sub_category) === 'number'){
            throw new Error('The product sub_category must to be a string!')
        }
        if(!await check_if_item_exist(product_id)){
            throw new Error(`This product id: ${product_id} does not exist`)
        }
        if(!await check_if_sub_category_belong_to_category(product_id,sub_category)){
            throw new Error(`אינך יכול לשייך תת מחלקה ${sub_category} למוצר זה!`)
        }else{
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                sub_category
            },{merge:true})   
        }
    }catch(err){
        throw new Error(err)
    }
}

async function set_nutrition (product_id,cal=0,colesterol=0,helbonim=0,humzot_ravuy=0,
    humzot_trans=0,kapiot_sukar=0,natran=0,pahmemot=0,pahmemot_sucar=0,shuman=0,sivim=0
    ){
    try{

        if(arguments.length != 12){
            throw new Error(`עליך להזין 12 פרמטרים!`)
        }

        const item = await getP_makat(product_id)

        const nutrition = item.nutrition

        for(let i=1; i < arguments.length-1; i++){
            switch (i) {
                case 1:
                    if(nutrition['cal']){
                        if(arguments[i] > 0){
                            nutrition['cal'] = cal
                        }
                    }else{
                        nutrition['cal'] = arguments[i]
                    }
                    break;
                case 2:
                    if(nutrition['colesterol']){
                        if(arguments[i] > 0){
                            nutrition['colesterol'] = colesterol
                        }
                    }else{
                        nutrition['colesterol'] = arguments[i]
                    }
                    break;
                case 3:
                    if(nutrition['helbonim']){
                        if(arguments[i] > 0){
                            nutrition['helbonim'] = helbonim
                        }
                    }else{
                        nutrition['helbonim'] = arguments[i]
                    }
                    break;
                case 4:
                    if(nutrition['humzot_ravuy']){
                        if(arguments[i] > 0){
                            nutrition['humzot_ravuy'] = humzot_ravuy
                        }
                    }else{
                        nutrition['humzot_ravuy'] = arguments[i]
                    }
                    break;
                case 5:
                    if(nutrition['humzot_trans']){
                        if(arguments[i] > 0){
                            nutrition['humzot_trans'] = humzot_trans
                        }
                    }else{
                        nutrition['humzot_trans'] = arguments[i]
                    }
                    break;
                case 6:
                    if(nutrition['kapiot_sukar']){
                        if(arguments[i] > 0){
                            nutrition['kapiot_sukar'] = kapiot_sukar
                        }
                    }else{
                        nutrition['kapiot_sukar'] = arguments[i]
                    }
                    break;
                case 7:
                    if(nutrition['natran']){
                        if(arguments[i] > 0){
                            nutrition['natran'] = natran
                        }
                    }else{
                        nutrition['natran'] = arguments[i]
                    }
                    break;
                case 8:
                    if(nutrition['pahmemot']){
                        if(arguments[i] > 0){
                            nutrition['pahmemot'] = pahmemot
                        }
                    }else{
                        nutrition['pahmemot'] = arguments[i]
                    }
                    break;
                case 9:
                    if(nutrition['pahmemot_sucar']){
                        if(arguments[i] > 0){
                            nutrition['pahmemot_sucar'] = pahmemot_sucar
                        }
                    }else{
                        nutrition['pahmemot_sucar'] = arguments[i]
                    }
                    break;
                case 10:
                    if(nutrition['shuman']){
                        if(arguments[i] > 0){
                            nutrition['shuman'] = shuman
                        }
                    }else{
                        nutrition['shuman'] = arguments[i]
                    }
                    break;
                case 11:
                    if(nutrition['sivim']){
                        if(arguments[i] > 0){
                            nutrition['sivim'] = sivim
                        }
                    }else{
                        nutrition['sivim'] = arguments[i]
                    }
                    break;
            }
        }

        if(!product_id){
            throw new Error(`Product id is missing`)
        }

        if(typeof(product_id) === 'number'){
            throw new Error(`The product id:${product_id} must to be a string!`)
        }

        if(await check_if_item_exist(product_id)){
            await projectFirestore.collection('Items').doc(product_id)
            .set({
                nutrition
            },{merge:true})
        }
        else{
            throw new Error(`This product id: ${product_id} does not exist`)
        }

    }catch(err){
        throw new Error(err)
    }
}







export{
    getP_barcode,
    getP_makat,
    getP_brand,
    getP_country,
    getP_merakez,
    getP_lastUpdate,
    getP_pikadon,
    getP_kosher,
    getP_showOnWeb,
    setP_image,
    setP_measure,
    setP_brand,
    setP_kosher_type,
    setP_order,
    setP_size,
    setP_category,
    setP_sub_category,
    set_nutrition,
    
}