

import { ref } from '@vue/reactivity'
import { projectStorage } from "../firebase/config";


const useStorage = () => {
  const error = ref(null);

  // url for download the image
  const url = ref(null);
  const filePath = ref('')
  const uploadImage = async (file, path) => {
    console.log(file.name);
    filePath.value = `${path}/${file.name}`;
    const storageRef = projectStorage.ref(filePath.value);

    try {
      // upload the file
      const res = await storageRef.put(file);
      url.value = await res.ref.getDownloadURL();
    } catch (err) {
      console.log(err.message);
      error.value = err.massage;
    }
  };

  // Delete the file
  const deleteImage = async path => {
    const storageRef = projectStorage.ref(path);

    try {
      await storageRef.delete();
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  };

  const delete_image_by_url = async (url) => {
    const storageRef = projectStorage.refFromURL(url);
    try {
      await storageRef.delete();
    } catch (err) {
      console.log(err.message);
      error.value = err.message;
    }
  }

  const downloadFile = async (fileUrl) => {
    try {
      const storageRef = projectStorage.refFromURL(fileUrl);

      const downloadUrl = await storageRef.getDownloadURL();

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank';
      link.download = ''; // Optionally set a filename here
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (err) {
      console.error("Error downloading file:", err);
      error.value = err.message;
    }
  };
  return { error, url, filePath, uploadImage, deleteImage, delete_image_by_url, downloadFile };
};

export default useStorage;