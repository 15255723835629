import Swal from 'sweetalert2';
import { projectFirestore, projectFunctions } from '../firebase/config'
import store from '../store';
import { slide_pop_error, slide_pop_info, alert } from './Msgs';
import { platform } from './Utils'
import router from '../router'

const load_user_catalog = async () => {
    //Step 1: איפוס
    store.state.catalog.categories = null;
    store.state.catalog.init = false;
    store.state.catalog.selected_category = null;
    store.state.catalog.selected_sub = null;
    store.state.catalog.items = null;
    store.state.rosClient = null;
    store.state.catalog.loaderText = 'מכין קטלוג לטעינה...';

    //Step 3: טעינת פריטים
    store.state.catalog.loaderText = 'טוען פריטים...';
    await refresh_items_b2c();

    //Step 4: טעינת מחלקות
    store.state.catalog.loaderText = 'טוען מחלקות...';
    await refresh_categories();

    //step 5: טעינת מחירונים בהתאם לסוג הלקוח
    store.state.catalog.loaderText = 'טוען מחירון מעודכן...';

    //Step 6: טעינת הנחות
    store.state.catalog.loaderText = 'טוען הנחות ומבצעים...';
    await get_discounts();
    await get_sales();

    //Step 7: כיבוי לואדר והצגה ללקוח
    store.state.catalog.loaderText = 'מסיים טעינה...';
    store.state.catalog.init = true;
    store.state.catalog.selected_category = store.state.catalog.categories[0].uid;
    store.state.catalog.selected_sub = store.state.catalog.categories[0].subs[0].uid;
}

const load_client_catalog = async () => {
    //Step 1: איפוס
    store.state.catalog.categories = null;
    store.state.catalog.init = false;
    store.state.catalog.selected_category = null;
    store.state.catalog.selected_sub = null;
    store.state.catalog.items = null;
    store.state.first_order_sales = [];
    store.state.catalog.loaderText = 'מכין קטלוג לטעינה...';

    //Step 3: טעינת פריטים
    store.state.catalog.loaderText = 'טוען פריטים...';
    await refresh_items_b2b();
    await clean_blocked();

    //Step 4: טעינת מחלקות
    store.state.catalog.loaderText = 'טוען מחלקות...';
    await refresh_categories();

    //step 5: טעינת מחירונים בהתאם לסוג הלקוח
    store.state.catalog.loaderText = 'טוען מחירון מעודכן...';

    //Step 6: טעינת הנחות
    store.state.catalog.loaderText = 'טוען הנחות ומבצעים...';
    await get_discounts();
    await get_sales();

    //Step 8: כיבוי לואדר והצגה ללקוח
    store.state.catalog.loaderText = 'מסיים טעינה...';

    store.state.catalog.init = true;

    store.state.catalog.selected_category = store.state.catalog.categories[0].uid;
    store.state.catalog.selected_sub = store.state.catalog.categories[0].subs[0].uid;

    if (store.state.rosClient && store.state.rosClient.first_order == true) {
        // console.log("First!")
        store.state.modal = 'NewPromo';
        const sales = store.state.first_order_sales;
        for (let i = 0; i < sales.length; i++) {
            let sale = sales[i];
            let inx = store.state.catalog.items.findIndex(inx => inx.makat == sale.items[0].makat);
            if (inx != -1) {
                store.state.catalog.items[inx].first_price = true;
                store.state.catalog.items[inx].price = sale.step_1.price;
                store.state.catalog.items[inx].max = sale.max;
                delete store.state.catalog.items[inx].discounts
                delete store.state.catalog.items[inx].sale
                sale.img = store.state.catalog.items[inx].db_image.img;
            }
        }
    }
}

const clean_blocked = async () => {
    if (store.state.rosClient && store.state.rosClient.merakez && (store.state.rosClient.merakez != '10001' && store.state.rosClient.merakez != '10002')) {
        let merakez_items = await projectFirestore.collection('Merakzim').doc(store.state.rosClient.merakez).get();
        if (merakez_items.exists) {
            // Clean Temporarly Blocked:
            let items = merakez_items.data().items.filter(itm => itm.valid == 'Y')
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let inx = store.state.catalog.items.findIndex(ip => ip.makat == items[i].makat);
                    if (inx != -1) {
                        // console.log("Removing Blocked: " + store.state.catalog.items[inx].makat , store.state.catalog.items[inx])
                        store.state.catalog.items.splice(inx, 1);
                    }
                }
            }
            // Clean items not found in merakez items:
            let rakez_items = merakez_items.data().items;
            for (let i = 0; i < store.state.catalog.items.length; i++) {
                let inx = rakez_items.findIndex(itm => itm.makat == store.state.catalog.items[i].makat);
                if (inx == -1) {
                    store.state.catalog.items.splice(i, 1);
                    i--;
                }
            }
        }
    }
}

const refresh_categories = async () => {
    var categories = [];
    try {
        const res = await projectFirestore.collection("Categories").get().then(docs => {
            categories = docs.docs.map(doc => doc.data());
        })
        for (let i = 0; i < categories.length; i++) {
            const rez = await projectFirestore.collection("Categories").doc(categories[i].uid).collection("Subs").orderBy('order', 'asc').get()
                .then(docs => {
                    if (!docs.empty) {
                        let subs = docs.docs.map(doc => doc.data());
                        let f_subs = [];
                        for (let j = 0; j < subs.length; j++) {
                            let sub = subs[j].uid;
                            let inx = store.state.catalog.items.findIndex(k => k.sub_category == sub);
                            if (inx != -1) {
                                f_subs.push(subs[j]);
                            }
                        }
                        categories[i].subs = f_subs;
                    }
                })
        }
        for (let i = 0; i < categories.length; i++) {
            let inx = store.state.catalog.items.findIndex(j => j.category == categories[i].uid);
            if (inx == -1 && categories[i].uid != 'JSV3rGM7an10gIkcxjEZ') {
                // console.log("Removing Category: ", categories[i])
                categories.splice(i, 1);
                i--;
            }
        }
        if (store.state.user) {
            categories.push({
                logo: '',
                name: 'מבצעים',
                order: -1,
                uid: 'salesUID',
                subs: [
                    {
                        name: 'מבצעים',
                        order: 0,
                        uid: 'salesSub'
                    }
                ]
            })
        }
        store.state.catalog.categories = categories.sort((aa, bb) => aa.order - bb.order);
        cats_clean();

    } catch (error) {
        slide_pop_error(error)
    }
}



const cats_clean = () =>{
    let preClean = store.state.catalog.categories;
        if(preClean){
            for(let i =0; i < preClean.length ;i++){
                let cat = preClean[i];
                let inx_cat = store.state.catalog.items.findIndex(itm => itm.category == cat.uid);
                if(inx_cat != -1){
                    for(let j = 0; j< cat.subs.length; j++){
                        let sub = cat.subs[j];
                        let inx = store.state.catalog.items.findIndex(itm => itm.sub_category == sub.uid);
                        if(inx == -1){
                            cat.subs.splice(j, 1);
                            j--;
                        }
                    }
                }
                if(cat.subs.length == 0){
                    preClean.splice(i, 1);
                    i--;
                }
            }
        }
}

const refresh_items_b2b = async () => {
    //תהפוך את הפונקציית ענן לאחד כל פעם ותריץ פה את הלולאה עצמה!
    let mehiron = store.state.rosClient.price_list_id;
    if (mehiron) {
        // fix migvan from merakez not from mehiron
        const clientAssortment = (await projectFirestore.collection('PriceLists').doc(mehiron).get()).data();
        const allItems = (await projectFirestore.collection('Items').get()).docs.map(doc => doc.data());
        if (clientAssortment) {
            let catalog = [];
            for (let i = 0; i < clientAssortment.items.length; i++) {
                let itm = allItems.findIndex(it => it.makat == clientAssortment.items[i].makat)
                if (itm != -1) {
                    let dt = allItems[itm];
                    dt.price = clientAssortment.items[i].price;
                    if(dt.is_featured){
                        let itm_copy = JSON.parse(JSON.stringify(dt));
                        itm_copy.category  ='JSV3rGM7an10gIkcxjEZ';
                        itm_copy.sub_category = 'cyxu3VEFLaNQgHpENytW';
                        catalog.push(itm_copy);
                    }
                    if(!dt.hidden){
                        catalog.push(dt);
                    }
                }
            }
            store.state.catalog.items = catalog;
        } else {
            store.state.catalog.loaderText = 'לא נמצא מחירון ללקוח, אנא פנה לתמיכה טכנית.';
            setTimeout(() => { return }, 3500);
        }
    } else {
        if (store.state.rosClient.merakez == '10001' || store.state.rosClient.merakez == '10002') {
            const allItems = (await projectFirestore.collection('Items').get()).docs.map(doc => doc.data());
            const clientAssortment = (await projectFirestore.collection('PriceLists').doc('בסיס').get()).data();
            let catalog = [];
            for (let i = 0; i < clientAssortment.items.length; i++) {
                let itm = allItems.findIndex(it => it.makat == clientAssortment.items[i].makat)
                if (itm != -1) {
                    let dt = allItems[itm];
                    dt.price = clientAssortment.items[i].price;
                    if(dt.is_featured){
                        let itm_copy = JSON.parse(JSON.stringify(dt));
                        itm_copy.category  ='JSV3rGM7an10gIkcxjEZ';
                        itm_copy.sub_category = 'cyxu3VEFLaNQgHpENytW';
                        catalog.push(itm_copy);
                    }
                    if(!dt.hidden){
                        catalog.push(dt);
                    }                }
            }
            store.state.catalog.items = catalog;
        } else {
            store.state.catalog.loaderText = 'לא נמצא מגוון ללקוח עסקי, אנא פנה לתמיכה טכנית.';
            setTimeout(() => { return }, 3500);
        }
    }

}

const refresh_items_b2c = async () => {
    const assortment = (await projectFirestore.collection('Assortment').get()).docs.map(doc => doc.data());
    const allItems = (await projectFirestore.collection('Items').get()).docs.map(doc => doc.data());
    let items = [];
    for (let i = 0; i < assortment.length; i++) {
        let pricer = assortment[i];
        let inx = allItems.findIndex(it => it.makat == pricer.makat)
        if (inx != -1) {
            let item = allItems[inx];
            if (item.showOnWeb != 'Z') {
                item.price = pricer.price;
                items.push(item)
            }
        }
    }
    store.state.catalog.items = items;
    return
}

const get_discounts = async () => {
    if (store.state.user && store.state.rosClient) {
        if (store.state.rosClient.merakez != '10001' && store.state.rosClient.merakez != '20000') {
            return
        }
        let res = await projectFirestore.collection("Discounts").doc("Priority").get();
        if (res) {
            res = res.data().discounts;
            // Create a set of merakez values from array of items
            const paritMerakez_set = new Set(store.state.catalog.items.map(obj => obj.paritMerakez));
            // Filter array B to only keep objects with merakez values that exist in the first arr
            const filtered_B = res.filter(obj => paritMerakez_set.has(obj.merakez));
            // Clear all 0.01 discounts
            filtered_B.forEach((obj, k) => {
                if (obj.prices) {
                    obj.prices = obj.prices.filter(ii => ii.DISCOUNT != 0.01);
                    if (obj.prices.length === 0) {
                        filtered_B.splice(k, 1);
                    }
                }
            })

            for (let i = 0; i < filtered_B.length; i++) {
                let merakez = filtered_B[i].merakez;
                store.state.catalog.items.forEach(itm => {
                    if (itm.paritMerakez == merakez) {
                        itm.discounts = filtered_B[i]
                    }
                })

            }
        } else {
            slide_pop_error("תקלה בעת טעינת הנחות - פנה לתמיכה טכנית.")
        }
    } else {
        return
    }
}

const get_sales = async () => {
    if (store.state.user && store.state.rosClient) {
        if (!store.state.rosClient.merakez) { return }
        let client_merakez = store.state.rosClient.merakez;
        let sales = [];
        const res = await projectFirestore.collection("Sales").where("merakez", '==', client_merakez).get();
        if (!res.empty) {
            sales = res.docs.map(doc => doc.data());
            sales.forEach(sale => {
                if (sale.first_order && !store.state.rosClient.first_order) {
                    return
                }

                if (sale.first_order && store.state.rosClient.first_order) {
                    store.state.first_order_sales.push(sale);
                    console.log("First Order Sale: ", sale)
                }

                let new_merakez = Math.random().toString().slice(2, 10);
                if (sale.date_end_date && sale.date_end_date.toDate() >= new Date()) {
                    if (sale.saleType == 'madregot') {
                        sale.items.forEach(itm => {
                            let inx = store.state.catalog.items.findIndex(ii => ii.makat == itm.makat);
                            if (inx != -1) {
                                store.state.catalog.items[inx].sale = sale;
                                store.state.catalog.items[inx].paritMerakez = new_merakez;
                                if (store.state.catalog.items[inx].discounts) {
                                    delete store.state.catalog.items[inx].discounts;
                                }
                            }
                        })
                    }
                    if (sale.saleType == 'kneKabel') {
                        sale.kne_kabel_items.forEach(itm => {
                            let inx = store.state.catalog.items.findIndex(ii => ii.makat == itm.makat);
                            if (inx != -1) {
                                store.state.catalog.items[inx].sale = sale;
                                store.state.catalog.items[inx].paritMerakez = new_merakez;
                                if (store.state.catalog.items[inx].discounts) {
                                    delete store.state.catalog.items[inx].discounts;
                                }
                            }
                        })
                    }
                }
            })
        } else {
            // let ink = store.state.catalog.categories.findIndex(inx => inx.uid == 'salesUID')
            // if(ink != -1){
            //     store.state.catalog.categories.splice(ink, 1);
            // }
        }
        return
    }
}

const select_category = (catUID) => {
    if (store.state.catalog.selected_category == catUID) {
        store.state.catalog.selected_category = null
    }
    else {
        store.state.catalog.selected_category = catUID;
        let inx = store.state.catalog.categories.findIndex(i => i.uid == catUID);
        if (inx != -1) {
            if (store.state.catalog.categories[inx].subs && store.state.catalog.categories[inx].subs.length > 0) {
                select_subCategory(store.state.catalog.categories[inx].subs[0].uid)
            }
        }
    }
}

const select_subCategory = (subUID) => {
    store.state.catalog.selected_sub = subUID;

    setTimeout(() => {
        var catalog_grid = document.getElementById("catalog_grid");
        if (!catalog_grid) {
            return
        }
        catalog_grid.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }, 200)

}



export { refresh_categories, load_user_catalog, select_category, select_subCategory, load_client_catalog }