
import { projectFirestore } from "../firebase/config"

const store_in_db = async(user,url)=>{
    const doc = projectFirestore.collection('Chat').doc('Messages')
    .collection(user.uid).doc()
    await doc.set({
        createdAt:new Date(),
        msgId:doc.id,
        name:user.displayName,
        image:url,
        type:'client'
    })
}

export{
    store_in_db
}