import { ref } from '@vue/runtime-core';

const show_update_alert = ref(false)

let sw_detailes = ref(null);
let refreshing = ref(false);

const update_sw_listenert = () => { 
    if(navigator.serviceWorker){
        document.addEventListener('serviceWorkerUpdateEvent', (e) => {
                console.log('serviceWorkerUpdateEvent');
                sw_detailes.value = e.detail;
                show_update_alert.value = true;
        }, { once: true });

        navigator.serviceWorker.addEventListener('controllerchange', () => {
            console.log('controllerchange');
            if (refreshing.value) return;
            refreshing.value = true;
            window.location.reload();
        });
    }
}

const handle_update_system = () => {
    show_update_alert.value = false;
    if(sw_detailes.value && sw_detailes.value.waiting){
      sw_detailes.value.waiting.postMessage({type:'SKIP_WAITING'});
    }
}



export{
    update_sw_listenert,
    handle_update_system,
    show_update_alert
}